import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-81abb35776/0/cache/next-npm-15.3.0-canary.24-ca0eedc230-8b9ada8835.zip/node_modules/next/dist/client/components/render-from-template-context.js");
