export const sendErrorToAPI = async (error) => {
    try {
        await fetch('/api/client-error-logger', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: error.message,
                stack: error.stack,
                name: error.name,
                ...error,
            }),
        });
    }
    catch (e) {
        console.error('Failed to send error to API:', e);
    }
};
const isDev = process.env.NODE_ENV === 'development';
export function juanCoreInstrumentationClient() {
    if (!isDev) {
        window.addEventListener('error', (event) => {
            sendErrorToAPI(event.error);
        });
        // Catching uncaught promise rejections
        window.addEventListener('unhandledrejection', (event) => {
            if (event.reason.name === 'AbortError') {
                event.preventDefault();
            }
            sendErrorToAPI(event.reason);
        });
    }
}
